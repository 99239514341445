import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Link from 'next/link';
import { ContactInfo, SocialInfo } from '../../types/CommonTypes';
import { LinkedInIcon, FacebookIcon, InstagramIcon } from '../Icons';
import { Text } from '../Typography';
type IconColor = 'black' | 'white';
interface SocialProps {
  info: ContactInfo;
  socials: Omit<SocialInfo, 'app'>;
  mediaIconsColor?: IconColor;
}
export const Socials = (props: SocialProps) => {
  const { t } = useTranslation('navigation');
  const { socials, info, mediaIconsColor } = props;
  const { followText } = info;
  const { facebook, linkedIn, instagram } = socials;
  const mediaIcons = mediaIconsColor === 'black' ? 'black' : 'white';
  return (
    <section className="flex flex-col pb-10 pt-7 lg:pb-0">
      <Text className="text-gray-250 pb-3 text-lg lg:pb-4">{followText}</Text>
      <div className="flex items-center space-x-4 pt-2 lg:pb-2">
        <Link href={linkedIn} target="_blank" aria-label={t('socials_aria_label') + ' Linkedin'}>
          <LinkedInIcon width={40} height={48} fill={mediaIcons} stroke={mediaIcons} />
        </Link>
        <Link href={facebook} target="_blank" aria-label={t('socials_aria_label') + ' Facebook'}>
          <FacebookIcon height={48} width={48} fill={mediaIcons} stroke={mediaIcons} />
        </Link>
        <Link href={instagram} target="_blank" aria-label={t('socials_aria_label') + ' Instagram'}>
          <InstagramIcon height={48} width={48} fill={mediaIcons} stroke={mediaIcons} />
        </Link>
      </div>
    </section>
  );
};
